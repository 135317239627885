import {
  update_product_currency,
  update_product_amount,
} from "./base/pages/products";
import { lightOrDark } from "../utils";

export function validateEmail(emailFieldId, confirmEmailFieldId) {
  const emailField = $(`#${emailFieldId}`);
  const confirmEmailField = $(`#${confirmEmailFieldId}`);
  if (emailField[0].value) {
    emailField[0].value = emailField[0].value.toLowerCase();
  }
  if (confirmEmailField[0].value) {
    confirmEmailField[0].value = confirmEmailField[0].value.toLowerCase();
  }
  if (
    emailField[0].value !== confirmEmailField[0].value &&
    emailField[0].required
  ) {
    emailField.addClass("is-invalid");
    emailField.removeClass("is-valid");
    confirmEmailField.addClass("is-invalid");
    confirmEmailField.removeClass("is-valid");
    return false;
  } else {
    emailField.addClass("is-valid");
    emailField.removeClass("is-invalid");
    confirmEmailField.addClass("is-valid");
    confirmEmailField.removeClass("is-invalid");
    return true;
  }
}

export function checkEmailConfirmation() {
  var validity = true;
  let email_submit_confirmation =
    document.getElementsByClassName("email-field-input");
  let email_list = [];
  if (email_submit_confirmation) {
    for (var i = 0; i < email_submit_confirmation.length; i++) {
      var id = email_submit_confirmation[i].id.split("-").slice(-1)[0];
      validity = validateEmail(`email-${id}`, `email-confirmation-${id}`);
      if (
        !!$(`#email-${id}`)[0].value &&
        email_list.includes($(`#email-${id}`)[0].value)
      ) {
        validity = false;
        $(`#email-${id}`).addClass("is-invalid");
        $(`#email-${id}`).removeClass("is-valid");
      }
      if (validity === false) {
        let form = $(`#email-${id}`)[0]?.form;
        $(form)?.removeClass("was-validated");
        break;
      }
      email_list.push($(`#email-${id}`)[0].value);
    }
  }
  return validity;
}

export function rutFormValidity(validity, form_id, element_id) {
  if (validity === false) {
    element_id.addClass("is-invalid");
    element_id.removeClass("is-valid");
    form_id.removeClass("was-validated");
  } else {
    element_id.addClass("is-valid");
    element_id.removeClass("is-invalid");
    form_id.addClass("was-validated");
  }
  return validity;
}

const min_amount_json_default = {
  uf: "0.02",
  clp: "1000",
  cop: "5000",
  eur: "1",
  usd: "1",
  pen: "5",
};

var locale = window.locale || "es";

export const validateAmountToDonate = (
  type,
  currency,
  element = "#donator_donation_transactions_attributes_0_amount"
) => {
  const amount_donator = $(element);

  const current_amount =
    currency === "uf"
      ? amount_donator.val()
      : parseInt(amount_donator.val().replace(/\./g, ""));
  let min_amount;
  let min_amount_json;

  if (type == "subscription" && $("#amount_form").attr("data-min-amount")) {
    min_amount_json = JSON.parse($("#amount_form").attr("data-min-amount"));
    min_amount = {
      clp: min_amount_json["clp"],
      cop: min_amount_json["cop"],
      usd: min_amount_json["usd"],
      uf: min_amount_json["uf"],
      eur: min_amount_json["eur"],
      pen: min_amount_json["pen"],
    }[currency];
  } else {
    min_amount = min_amount_json_default[currency];
  }

  if (
    current_amount >= min_amount ||
    amount_donator[0].dataset.validate === "false"
  ) {
    $(element).removeClass("is-invalid");
    $("#new_donator").addClass("was-validated");
    $(`#amount_form div.invalid-feedback.payment-${currency}`).hide();
    return { valid: true, amount: current_amount };
  } else {
    $(element).addClass("is-invalid");
    $("#new_donator").removeClass("was-validated");
    $(`#amount_form div.invalid-feedback`).hide();
    $(`#amount_form div.invalid-feedback.payment-${currency}`).show();
    return { valid: false, amount: current_amount };
  }
};
export const manageAvailableCurrencies = (currency, type) => {
  const valid = {
    subscription:
      'label[data-currency="clp"],label[data-currency="cop"],label[data-currency="usd"],label[data-currency="uf"]',
    once: 'label[data-currency="pen"], label[data-currency="cop"], label[data-currency="usd"], label[data-currency="clp"], label[data-currency="eur"], label[data-currency="uf"]',
  };
  $("#currency-type").val(currency);
  $(".currency-selection label").addClass("btn-disabled");
  $(".currency-selection").find(valid[type]).removeClass("btn-disabled");
  const raffle_amounts = document.querySelector("#raffle-amounts");
  if (raffle_amounts) {
    var amount = $(raffle_amounts).attr(`data-ticket-${currency}-amount`);
    $(".campaign-product-price").hide();
    $(`.${currency}-ticket-price`).show();
    $("#product-quantity-desktop").attr("data-price", amount);
    $("#product-quantity-mobile").attr("data-price", amount);
    const total_amount = amount * $("#product-quantity-desktop").val();
    update_product_amount(total_amount);
  }
};

export const managePaymentOptions = (currency, type) => {
  manageAvailableCurrencies(currency, type);
  validateAmountToDonate(type, currency);
  // Set active type
  $(".donation-type-select button").removeClass("btn-active");
  $(".donation-type-select button").removeClass("active");
  $(`.donation-type-select button[data-type="${type}"]`).addClass("btn-active");
  $(`.donation-type-select button[data-type="${type}"]`).addClass("active");

  // Set active currency
  $(".currency-selection label").removeClass("btn-active");
  $(".currency-selection label").removeClass("active");
  $(`.currency-selection label[data-currency="${currency}"]`).addClass(
    "btn-active"
  );
  $(`.currency-selection label[data-currency="${currency}"]`).addClass(
    "active"
  );

  // Show/hide available options
  const selector = ".payment-selection .custom-control";
  var donation_type = "";
  if (type == "cards" || type == "charity-wreaths" || type == "cards2") {
    donation_type = "cards";
    $("#currency-type-card")[0].value = currency;
  } else if (type == "projects" || type == "wizo-subscription")
    donation_type = "once";
  else {
    donation_type = type;
  }
  $(`${selector}`).addClass("hidden-input");
  $(`${selector}.payment-${currency}.payment-${donation_type}`).removeClass(
    "hidden-input"
  );
  $(".price-cards.payment-subscription, .price-cards.payment-once").addClass(
    "hidden-input"
  );
  $(`.price-cards.payment-${donation_type}`).removeClass("hidden-input");
  $(`${selector}:not(.hidden-input) input`).first().prop("checked", true);
  $(".donator_authenticatable").prop("checked", type === "subscription");
};

export const setCurrency = (currency, type, amountin) => {
  $("#currency-type").val(currency);
  amountin[0].set("");
  let min_amount_json;
  managePaymentOptions(currency, type);
  if (!!$("#cause-product-price")[0] || !!$(".product-quantity-container")[0]) {
    let cause_amount = update_product_currency(currency);
    amountin[0].set(cause_amount);
  }
  if ($("#amount_form").attr("data-min-amount")) {
    min_amount_json = JSON.parse($("#amount_form").attr("data-min-amount"));
  } else {
    min_amount_json = min_amount_json_default;
  }
  changeRecommendedAmountText(type, currency);
  locale = window.locale || "es";
  let min_amount_text =
    locale == "en"
      ? "Please, type a minimum amount of "
      : "Por favor, ingresa un monto mínimo de ";
  if (type == "once") {
    $(`#min-amount-donation-text-${currency}`).text(
      min_amount_text +
        min_amount_json_default[currency] +
        " " +
        currency.toString().toUpperCase()
    );
    $("#donator_donation_transactions_attributes_0_amount").prop(
      "placeholder",
      `${locale == "es" ? "Mínimo de " : ""}` +
        min_amount_json_default[currency] +
        " " +
        currency.toString().toUpperCase()
    );
  } else if (type == "subscription") {
    $(`#min-amount-donation-text-${currency}`).text(
      min_amount_text +
        min_amount_json[currency] +
        " " +
        currency.toString().toUpperCase()
    );
    $("#donator_donation_transactions_attributes_0_amount").prop(
      "placeholder",
      `${locale == "es" ? "Mínimo de " : ""}` +
        min_amount_json[currency] +
        " " +
        currency.toString().toUpperCase()
    );
  }
  if (currency == "uf") {
    amountin[0].update({
      digitGroupSeparator: ",",
      decimalCharacter: ".",
      decimalPlaces: 2,
    });
  } else {
    amountin[0].update({
      digitGroupSeparator: ".",
      decimalCharacter: ",",
      decimalPlaces: 0,
    });
  }
};

export const subscriptionTypeSettings = (locale, min_amount_json, currency) => {
  $("#donator_email_label").html(locale == "en" ? "Mail" : "Ingresa tu email");
  $(".donator-login-button").removeClass("hidden-input");
  $("#amount_form.dynamic-label label").text(
    locale == "en" ? "Monthly amount" : "Monto a donar mensualmente"
  );
  $("#donator_email_fundraising").prop("required", true);
  $("#donator_email_platform").prop("required", true);
  $("#donator_email_fundraising").addClass("form-control", true);
  $(".currency-selection label[data-currency='uf']").show();
  $(".currency-selection label[data-subscription='false']").hide();
  $(".platform").removeClass("platform-once");
  $(".platform").removeClass("platform-cards");
  $(".platform").removeClass("platform-cards2");
  $(".platform").addClass("platform-subscription");
  $(".platform").removeClass("platform-causes");
  $(".platform").removeClass("projects");
  $("#subscription-header").show();
  $("#once-header").hide();
  $("#donator_donation_transactions_attributes_0_amount").prop(
    "placeholder",
    `${locale == "es" ? "Mínimo de " : ""}` +
      min_amount_json[currency] +
      " " +
      currency.toString().toUpperCase()
  );
  changeRecommendedAmountText("subscription", currency);
  let min_amount_text =
    locale == "en"
      ? "Please, type a minimum amount of "
      : "Por favor, ingresa un monto mínimo de ";
  $(`#min-amount-donation-text-${currency}`).text(
    min_amount_text +
      min_amount_json[currency] +
      " " +
      currency.toString().toUpperCase()
  );
  $(".app-container").show();
  $(".card-container").hide();
  $(".greeting-cards-banner").hide();
  $(".charity-wreaths-container").hide();
  $(".charity-wreaths-banner").hide();
  $(".form-title.subscription-header").show();
  $(".form-title.once-header").hide();
  $("#projects-form-container").hide();
  $("#donations-form-container").show();
  $("#recurring-radio-container").show();
  $(".causes-container").hide();
  $(".causes-banner").hide();
  if ($("#subscription-title").text()) {
    $("#basic-platform-title").text($("#subscription-title").text());
  }
};

export const onceTypeSetting = (
  currency,
  type,
  amountin,
  single_optional_email
) => {
  $(".donator-login-button").addClass("hidden-input");
  $("#amount_form.dynamic-label label").text(
    locale == "en" ? "Amount" : "Monto a donar"
  );
  $("#donator_email_label").html(
    locale == "en"
      ? `Mail ${single_optional_email == "false" ? "" : "(Optional)"}`
      : `Email ${single_optional_email == "false" ? "" : "(Opcional)"}`
  );
  $("#donator_email_fundraising").prop("required", false);
  $("#donator_email_fundraising").removeClass("form-control", true);
  $("#donator_email_platform").prop(
    "required",
    single_optional_email == "false" ? true : false
  );
  changeRecommendedAmountText("once", currency);
  let min_amount_text =
    locale == "en"
      ? "Please, type a minimum amount of "
      : "Por favor, ingresa un monto mínimo de ";
  $(".currency-selection label[data-currency='usd']").show();
  $(".platform").removeClass("platform-cards");
  $(".platform").removeClass("platform-cards2");
  $(".platform").removeClass("platform-subscription");
  $(".platform").addClass("platform-once");
  $(".platform").removeClass("platform-causes");
  $(".platform").removeClass("projects");
  $("#once-header").show();
  $("#subscription-header").hide();
  $("#donator_donation_transactions_attributes_0_amount").prop(
    "placeholder",
    `${locale == "es" ? "Mínimo de " : ""}` +
      min_amount_json_default[currency] +
      " " +
      currency.toString().toUpperCase()
  );
  $(`#min-amount-donation-text-${currency}`).text(
    min_amount_text +
      min_amount_json_default[currency] +
      " " +
      currency.toString().toUpperCase()
  );
  $(".app-container").show();
  $(".card-container").hide();
  $(".greeting-cards-banner").hide();
  $(".charity-wreaths-container").hide();
  $(".charity-wreaths-banner").hide();
  $(".form-title.subscription-header").hide();
  $(".form-title.once-header").show();
  $("#projects-form-container").hide();
  $("#donations-form-container").show();
  $("#recurring-radio-container").show();
  $(".causes-container").hide();
  $(".causes-banner").hide();
  if ($("#once-title").text()) {
    $("#basic-platform-title").text($("#once-title").text());
  }
};

export const cardsTypeSetting = (locale) => {
  $(".donator-login-button").addClass("hidden-input");
  $("#amount_form.dynamic-label label").text(
    locale == "en" ? "Amount" : "Monto a donar"
  );
  $(".currency-selection label[data-currency='usd']").removeClass("active");
  $(".currency-selection label[data-currency='usd']").css(
    "display",
    "flex !important"
  );
  $(".platform").removeClass("platform-subscription");
  $(".platform").removeClass("platform-once");
  $(".platform").removeClass("platform-cards2");
  $(".platform").addClass("platform-cards");
  $(".platform").removeClass("projects");
  $(".platform").removeClass("platform-causes");
  $("#subscription-header").hide();
  $("#once-header").hide();
  $(".app-container").hide();
  $(".card-container").show();
  $(".cards-container").show();
  $(".greeting-cards-banner").show();
  $(".charity-wreaths-container").hide();
  $(".charity-wreaths-banner").hide();
  $(`.select-card-container`).hide();
  $("#projects-form-container").hide();
  $("#donations-form-container").show();
  $("#recurring-radio-container").show();
  $(".causes-container").hide();
  $(".causes-banner").hide();
};

export const cards2TypeSetting = (locale) => {
  $(".donator-login-button").addClass("hidden-input");
  $("#amount_form.dynamic-label label").text(
    locale == "en" ? "Amount" : "Monto a donar"
  );
  $(".currency-selection label[data-currency='usd']").removeClass("active");
  $(".currency-selection label[data-currency='usd']").css(
    "display",
    "flex !important"
  );
  $(".platform").removeClass("platform-subscription");
  $(".platform").removeClass("platform-once");
  $(".platform").removeClass("platform-cards");
  $(".platform").addClass("platform-cards2");
  $(".platform").removeClass("platform-causes");
  $(".platform").removeClass("projects");
  $(".app-container").hide();
  $(".card-container").show();
  $(".cards-container").hide();
  $("#subscription-header").hide();
  $("#once-header").hide();
  $(".greeting-cards-banner").hide();
  $(".charity-wreaths-container").show();
  $(".charity-wreaths-banner").show();
  $(".select-card-container").hide();
  $("#projects-form-container").hide();
  $("#donations-form-container").show();
  $("#recurring-radio-container").show();
  $(".causes-container").hide();
  $(".causes-banner").hide();
};

export const projectsTypeSetting = (locale) => {
  $(".donator-login-button").addClass("hidden-input");
  $("#amount_form.dynamic-label label").text(
    locale == "en" ? "Amount" : "Monto a donar"
  );
  $(".currency-selection label[data-currency='usd']").removeClass("active");
  $(".currency-selection label[data-currency='usd']").css(
    "display",
    "flex !important"
  );
  $(".platform").removeClass("platform-subscription");
  $(".platform").removeClass("platform-once");
  $(".platform").removeClass("platform-cards");
  $(".platform").removeClass("platform-cards2");
  $(".platform").removeClass("platform-causes");
  $(".platform").addClass("projects");
  $(".card-container").hide();
  $(".cards-container").hide();
  $(".greeting-cards-banner").hide();
  $(".charity-wreaths-container").hide();
  $(".charity-wreaths-banner").hide();
  $(".select-card-container").hide();
  $("#projects-form-container").show();
  $("#donations-form-container").hide();
  $(".causes-container").hide();
  $(".causes-banner").hide();
  $("#recurring-radio-container").hide();
};

export const causesTypeSetting = () => {
  $(".donator-login-button").addClass("hidden-input");
  $(".platform").removeClass("platform-once");
  $(".platform").removeClass("platform-subscription");
  $(".platform").removeClass("platform-cards");
  $(".platform").removeClass("platform-cards2");
  $(".platform").addClass("platform-causes");
  $(".app-container").hide();
  $("#subscription-header").hide();
  $("#once-header").hide();
  $(".card-container").hide();
  $(".cards-container").hide();
  $(".greeting-cards-banner").hide();
  $(".charity-wreaths-container").hide();
  $(".charity-wreaths-banner").hide();
  $(".select-card-container").hide();
  $(".causes-container").show();
  $(".causes-banner").show();
  $("#donations-form-container").hide();
  $("#recurring-radio-container").hide();
};

export const donandoFooterColor = () => {
  const footer_color = $(".donando-footer").attr("data-footer-color");
  if (footer_color) {
    const color_type = lightOrDark(footer_color);
    const footer_images = Array.from(
      document.getElementsByClassName("donando-footer-image")
    );
    const social_media_icons = $(".social-media-footer-icon");
    if (color_type == "light") {
      footer_images.map((footer) => {
        footer.src =
          "https://storage.googleapis.com/fundingplatform-files/assets/donando/desarrollado-donando.png";
      });
      social_media_icons.each((element, data) => {
        data.style.color = "#000000";
      });
    } else if (color_type == "dark") {
      footer_images.map((footer) => {
        footer.src =
          "https://storage.googleapis.com/fundingplatform-files/assets/donando/desarrollado-donando-negative.png";
      });
      social_media_icons.each((element, data) => {
        data.style.color = "#ffffff";
      });
    }
  }
};

export const changeRecommendedAmountText = (donation_type, currency) => {
  let recommended_amount_json;
  let recommended_amount_text =
    locale == "en"
      ? "We recommend a donation of "
      : "(Recomendamos un monto de ";
  if (
    $("#amount_form").attr("data-recommended-amount") &&
    $("#amount_form").attr("data-recommended-amount") != "null" &&
    $(`#recommended-amount-text`)
  ) {
    recommended_amount_json = JSON.parse(
      $("#amount_form").attr("data-recommended-amount")
    );
    if (
      recommended_amount_json[currency] !== undefined &&
      recommended_amount_json[currency] !== "" &&
      donation_type === "subscription" &&
      currency !== "uf"
    ) {
      const formattedAmount = Number(
        recommended_amount_json[currency]
      ).toLocaleString(locale, {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      $(`#recommended-amount-text`).text(
        recommended_amount_text +
          formattedAmount +
          " " +
          currency.toUpperCase() +
          ")"
      );
    } else {
      $(`#recommended-amount-text`).text("");
    }
  }
};
